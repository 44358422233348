import React, {useRef} from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"
import TextField from "./Utils/TextField"
import Snackbar from "./Utils/Snackbar"
import Button from "./Utils/Button"
import { Link } from "gatsby"
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';



const styles = theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: "flex",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",   
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
  },
  // imageDots: {
  //   position: "absolute",
  //   top: -67,
  //   left: -67,
  //   right: 0,
  //   bottom: 0,
  //   width: "100%",
  //   background: "url(/static/onepirate/productCTAImageDots.png)",
  // },
 /*  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  }, */

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  },
  
})

function ContactForm(props) {
  const { classes } = props
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [msg, setMsg] = React.useState('')
  const form = useRef(null)

  const handleEmailChange = event => {
    setEmail(event.target.value)
  };
  const handleMsgChange = event => {
    setMsg(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault()

    const url = '/scripts/mailme'

    const data = new FormData(form.current)
    fetch(url, { method: 'POST', body: data })
      .then(res => res.json())
      //.then(json => setUser(json.user))

      setOpen(true)

/*      const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ email, msg })        
     };
     fetch(url, requestOptions)
         .then(response => setOpen(true))
         .catch(error => alert('Fehler beim Versand:', error)) */
    //alert(JSON.stringify({ email, msg }))
    
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container className={classes.root} component="section" >
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form ref={form} onSubmit={handleSubmit} className={classes.cardContent}>
              <Typography variant="h2" component="h2" gutterBottom>
                Kommen Sie zur Beratung,
              </Typography>
              <Typography variant="h5">
                unsere Praxis liegt direkt an der B75. Kostenfreie Parkplätze vorhanden.<br/><br/>
              </Typography>
      
              {/* <TextField
                noBorder
                className={classes.textField}
                placeholder="Ihre e-mail"
              /> */}
            <div >
{/*               <label>Email address :&nbsp;</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={handleEmailChange}
                value={email}
              /> */}
            <TextField         
                required 
                id="outlined-required"
                variant="outlined"
                className={classes.textField}
                name="email"
                label="Ihre Email Adresse:"
                style={{ margin: 8 }}
                placeholder="Eingabe Email Adresse"
                fullWidth
                margin="normal" 
                size="medium" // ehem. normal
                value={email}               
                onChange={handleEmailChange}
                helperText="Bitte geben Sie hier Ihre E-Mail Adresse für die Kontaktaufnahme an."
             />

            </div>
            <div>
            <div>
            <br/>
            <TextField         
                id="outlined-multiline-flexible"
                variant="outlined"
                className={classes.textField}
                name="msg"
                label="Ihre Nachricht:"
                style={{ margin: 8 }}
                placeholder="Eingabe Nachricht"
                fullWidth
                margin="normal" 
                multiline
                size="medium" // ehem. normal?
                value={msg}               
                onChange={handleMsgChange}
                helperText="Wir freuen uns auf Ihre Nachricht."
             />
             </div>
              {/* <label>Nachricht: &nbsp;</label>
              <input
                type="text"
                name="msg"
                placeholder="Nachricht"
                onChange={handleMsgChange}
                value={msg}
              /> */}
              <div>
                <Typography variant="body2">
                  
                   <em> Datenschutzhinweis:</em> Ihre Informationen werden nur für die unmittelbare Anfrage genutzt und nicht dauerhaft gespeichert.
                    Mehr Details <Link to="/datenschutz">Datenschutzhinweis</Link>
                    <br/><br/>
                  
                </Typography>
              </div>
            </div>            
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Bitte senden Sie mir mehr Informationen
              </Button>
              
            </form>
          </div>
        </Grid>



        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
              <form>
            <Typography variant="h2" component="h2" gutterBottom>
                Social Media<br/><br/>
              </Typography>
              <Typography variant="h5">
                wir sind auf Facebook, Instagram und Twitter aktiv und 
                informieren Sie hier rund um die Themen Ästhetik, Medizische Informatik und Anti-Aging:<br/><br/>
              </Typography>              
      
              <Typography variant="body1">
                <a 
                  href="https://www.facebook.com/estetiese/"                   
                  aria-label="Link zu Estetiese auf Facebook."
                  >
                 <FacebookIcon/>  facebook.com/estetiese
                </a>
                <br/><br/>
                <a 
                  href="https://www.facebook.com/Mesotherapie.Bremen/"                   
                  aria-label="Link zu Estetiese auf Facebook."
                  >
                 <FacebookIcon/>  facebook.com/Mesotherapie.Bremen
                </a>
                <br/><br/>
                <a 
                  href="https://www.facebook.com/aesthetische.medizin.bremen/"                   
                  aria-label="Link zu Estetiese auf Facebook."
                  >
                 <FacebookIcon/>  facebook.com/Aesthetische.Medizin.Bremen
                </a>
                <br/><br/>
                <a 
                  href="https://www.facebook.com/Haarausfall.Bremen/"                   
                  aria-label="Link zu Estetiese auf Facebook."
                  >
                 <FacebookIcon/>  facebook.com/Haarausfall.Bremen
                </a>
                <br/><br/><br/>

                <a
                  href="https://twitter.com/estetiesebremen/"                  
                  aria-label="Link zu EstetieseBremen auf Instagram"
                >
                  <TwitterIcon/> twitter.com/EstetieseBremen
                </a>
                <br/><br/><br/><br/>
                <a
                  href="https://www.instagram.com/docestetiese/"                  
                  aria-label="Link zu DocEstetiese auf Instagram"
                >
                  <InstagramIcon  /> instagram/docestetiese
                </a>
                </Typography>
                <br/><br/> 
                </form>
          </div>
        </Grid>
        </Grid>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="Danke für Ihre Anfrage, wir werden Ihnen in kürze ein Informationspaket senden."
      />
      
    </Container>
  )
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactForm)
