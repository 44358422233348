import React,{useState, useEffect } from "react"
import { Link } from 'gatsby'
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"  
import Typography from "./Typography"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import MapIcon from '@material-ui/icons/Map';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


import ButtonBase from "@material-ui/core/ButtonBase"
import Image from "../Image"
import { red } from "@material-ui/core/colors"
import ConfirmDialog from "./ConfirmDialog"



/* Alternativ: https://github.com/masotime/react-leaflet-universal */



const styles = theme => ({
    root: {
      display: "flex",
      backgroundColor: theme.palette.secondary.light,
      overflow: "hidden",
    },
    container: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(15),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5),
    },
    title: {
      marginBottom: theme.spacing(14),
    },
    hint:{
        color:red,
    },
    number: {
      fontSize: 24,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    image: {
      height: 55,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
     button: {
      marginTop: theme.spacing(8),
    },
    images: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexWrap: "wrap",
    },
    imageWrapper: {
      position: "relative",
      display: "block",
      padding: 0,
      borderRadius: 0,
      height: "40vh",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 240,
      },
      "&:hover": {
        zIndex: 1,
      },
/*       "&:hover $imageBackdrop": {
        opacity: 0.15,
      },
      "&:hover $imageMarked": {
        opacity: 0,
      },
      "&:hover $imageTitle": {
        border: "4px solid currentColor",
      }, */
    },
    imageButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: "absolute !important",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.common.black,
      opacity: 0.5,
      transition: theme.transitions.create("opacity"),    
    },
    imageTitle: {
      position: "relative",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: "absolute",
      bottom: -2,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity"),
    },
  })

function MyMap(props) {
    const { classes } = props
  useEffect(() => {
    const L = require("leaflet");
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize: [24,36],
      iconAnchor: [12,36]
    });

    L.Marker.prototype.options.icon = DefaultIcon; 
/*     delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    }); */
  }, []);
  const [confirmOpen, setConfirmOpen] = useState( false)
  const [navbarOpen, setNavbarOpen] = useState(false)
  const position = [53.05357, 8.7424]
  const message = "EMSCulpt NEO - Privatpraxis ESTETIESE"

  return (
<>
    {navbarOpen ? (
        <MapContainer className="markercluster-map"  center={position} zoom={17} style={{zIndex: 0, height: '420px',}} >
        <TileLayer
        attribution='&amp;copy; <a href="https://www.openstreetmap.de/copyright">OpenStreetMap</a> Mitwirkende'
        url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
        <Popup>
            "{message}"
        </Popup>
        </Marker>
        ):
            (

            )
        </MapContainer>
      ) : (
        <Typography   
        component={'span'}          
        variant="body1"
        gutterBottom
        align="justify"
          >

      <div >
        <b>Hinweis:</b><br/>  <em>Wenn Sie auf die unten angezeite Karte klicken, erteilen Sie damit die Freigabe der Anzeige der "OpenStreetmap" Karte und der Links zu externen Seiten von 
        &#8203;Openstreetmap,                         
          Google und Bing &#8203;aktzeptieren Sie <Link to="/datenschutz/#osm">die Datenschutz&shy;bestimmungen</Link> dieser Seite.</em>
      </div>
              <ConfirmDialog
                    title="Datenschutzhinweis"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={()=>{setNavbarOpen(!navbarOpen)}}
                >
                Zugriff auf die OSM Daten erlauben. Sie stimmen hiermit zu das Ihre IP Adresse hierzu an Open Streetmap Deutschland übertragen wird.
              </ConfirmDialog>
  
        <ButtonBase
            key="OSMKarteNorderoog1"
            className={classes.imageWrapper}
            style={{
              width: "100%",
            }}
            //onClick={() =>{ setConfirmOpen(true)}
            onClick={() =>     setConfirmOpen(true)}
                /*  if (false) {setNavbarOpen(!navbarOpen)}*/
                
              //}              
          >

            <Image src="Norderog1_Detail.png" alt="Kartenansicht Norderoog 1 aus Open Street Map" className={classes.imageSrc}/>

            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                <MapIcon/> <br/>Norderoog 1<br/>28259 Bremen
                <div className={classes.imageMarked} />
              </Typography>
            </div>            
         </ButtonBase>          



{/*         <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<MapIcon/>}
              onClick={() => setNavbarOpen(!navbarOpen)}
              >
              Karte anzeigen
          </Button>    
 */}

         </Typography>
      )
    }     
    

</>
  );
};


MyMap.propTypes = {
    classes: PropTypes.object.isRequired,
  }
  
  export default withStyles(styles)(MyMap)